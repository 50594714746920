import React from 'react'
import PropTypes from 'prop-types'

import { graphql } from 'gatsby'

import Layout from '../components/layout/layout'
import SEO from '../components/seo'

const Privacy = ({ data }) => (
  <Layout>
    <SEO title="Privacy Policy" />
    <div dangerouslySetInnerHTML={{ __html: data.iubendaDocument.privacyPolicy }} />
  </Layout>
)

Privacy.propTypes = {
  data: PropTypes.shape({
    iubendaDocument: PropTypes.shape({
      privacyPolicy: PropTypes.string,
    }),
  }).isRequired,
}

export default Privacy

export const query = graphql`
query Privacy {
  iubendaDocument(documentId: {eq: "28834528"}) {
    privacyPolicy
  }
}
`
